import { Container, Grid, Popover, Skeleton, Typography } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import React, {useEffect, useState} from 'react'
import { Button } from 'react-bootstrap';
import { ThemeContext } from '../App';
import CssBaseline from '@mui/material/CssBaseline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ChannelModal from '../web-components/ChannelDetailsModal';
import Playlist from './Playlist';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import Banners from '../banners/Banners';
import { Form} from 'react-bootstrap'
import './broadcast.scss';
import Search from '../search/Search';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Payments from '../stripe/Payments';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
  };

export default function Broadcast(props) {
    var { channelId, programId } = useParams();
    const singleChannelAPI = props.singleChannelAPI;
    
    // console.log("use params", channelId, programId);
  const [channelCategoryGroups, setChannelCategoryGroups] = React.useState([]);
  // const classes = useStyles();
  const theme = React.useContext(ThemeContext);
//   console.log("theme from context", theme);
  const [programsAPI, setProgramsAPI] = React.useState(null);
  const [programs, setPrograms] = React.useState(null);
  const [channelID, setChannelID] = React.useState(null);
  const [channelName, setChannelName] = React.useState(null);
  const [channelImage, setChannelImage] = React.useState(null);
  const [channelDescription, setChannelDescription] = React.useState(null);
  const [channelType, setChannelType] = React.useState(null);
  const [channelBaseUrl, setChannelBaseUrl] = React.useState(null);
  const [randomize, setRandomize] = useState(false);
  const [open, setOpen] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openPopoverId, setOpenPopoverId] = React.useState(null);
  const onChannelClick = props.onChannelClick;
  const [loading, setLoading] = useState(true);
  const bannersAPI = props.bannersAPI;
  const clientID = localStorage.getItem("clientID");
  const [singleChannel, setSingleChannel] = React.useState(null);

  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      // If an array already present for key, push it to the array. Else create an array and push the object
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };

  //console.log("Channel params", props.match.params);

useEffect(()=>{
    console.log("channel ID", channelId);
    if(channelId) {
        fetch(`${singleChannelAPI}?channelID=${channelId}`)
        .then(result => result.json())
        .then(result => {
            openSingleChannel(result[0].id, 0, result[0].randomize, result[0].channel_image, result[0].title, result[0].channel_description, result[0].base_url, props.programsAPI, result[0].channel_type)
        },
        (error) => {
            console.error("ERROR", error.message);
        }) 
    }
},[channelId])

  useEffect(() => {
    // setTimeout(() => {
        setChannelCategoryGroups(props.channelCategories);
        console.log("channel categories", props.channelCategories);
    // }, 1000);
    // if (props.channelCategories) {
    //     console.log("fetch channel", props.channelCategories)
    // } else {
    //     console.error("Do not fetch channel");
    // }
  },[props.channelCategories])
  
//   state={{id: filteredItem.id, subscription_plan: filteredItem.subscription_plan, price: filteredItem.price, payment_frequency: filteredItem.payment_frequency, description: filteredItem.title}}
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = (e) => {
        e.stopPropagation();
        setOpenModal(true);
    }
    const handleCloseModal = (e) => {
        e.stopPropagation();
        setOpenModal(false);
    }
  const openChannel = (id, i, randomize, channel_image, title, channel_description, base_url, programsAPI, channelType) => { 
    setChannelBaseUrl(base_url);
      setChannelID(id);
      setChannelImage(channel_image);
      setChannelName(title);
      setChannelDescription(channel_description);
      setProgramsAPI(programsAPI);
      setRandomize(randomize);
      setChannelType(channelType);
      window.scrollTo(0,0);
      onChannelClick(false);
  }    

  const openSingleChannel = (id, i, randomize, channel_image, title, channel_description, base_url, programsAPI, channelType) => {
    setChannelBaseUrl(base_url);
    setChannelID(id);
    setChannelImage(channel_image);
    setChannelName(title);
    setChannelDescription(channel_description);
    setProgramsAPI(programsAPI);
    setRandomize(randomize);
    setChannelType(channelType);
    window.scrollTo(0,0);
    onChannelClick(false);
} 

  const handlePopoverOpen = (event, id) => {
      setOpenPopoverId(id);
      setAnchorEl(event.currentTarget);
      setOpen(true);
  };

  const handleClose = (e) => {
    e.stopPropagation();
      setAnchorEl(null);
      setOpenPopoverId(null);
  };

  const toggleFavorite = (e, channelId, channelTitle) => {
      e.stopPropagation();
  }

//   function getSubscription (e, id, subscription_plan, price, payment_frequency) {
//     e.stopPropagation();
//     if(subscription_plan.includes("http")) {
//         useNavigate(payment)
//     } else {
//         console.log("subscription plan", id, subscription_plan, price, payment_frequency);
//         //window.location(id, subscription_plan, price, payment_frequency)
//     }
//   }

//   const handleSearch = (e) => {
//     console.log("search", e.target.value)
//   } 
  
  return (
    <>
      <div id="channelContainer" style={theme ? {maxWidth: '100%', color: theme[0].primary_color} : null}>
            <CssBaseline />
            {/* <Search channelCategoryGroups={channelCategoryGroups} /> */}
            
            {channelCategoryGroups ? (<Container className="mainBody" style={theme ? {maxWidth: '100%',  height: '100%', minHeight: '800px', paddingTop: '24px'} : null}>
                <Grid item xs={12} className="playListContainer" style={theme ? {borderBottom: `${theme[0].title_bar_background_color} solid 2px`} : null}>
                    <Playlist channelID={channelID} baseUrl={props.baseUrl} channelBaseUrl={channelBaseUrl} programsAPI={programsAPI} theme={theme} channelName={channelName} channelImage={channelImage} randomize={randomize} programs={programs} channelType={channelType} />
                    
                </Grid>
                <Grid key={Math.random()} className="spliderGridContainer" item xs={12} style={{maxHeight: '100%', maxWidth: '100%', overFlowY: 'auto'}}>
                    <Grid className="splideGrid" item xs={12}>
                        {channelCategoryGroups && channelCategoryGroups.map((channelCategories, i) => (
                            channelCategories[1].map((channelCategory, i) => (
                                channelCategory && <div key={i}>
                                    <h3 key={channelCategory.id} style={{color: theme[0].page_font_color, textShadow: theme[0].page_text_shadow}}>{channelCategory.title}</h3>
                                    <Splide
                                        options={ {
                                            type: 'slide',
                                            gap: '1rem',
                                            perMove: 1,
                                            arrows: channelCategory.channels.length > 1 ? true : false,
                                            pagination: false,
                                            waitForTransition: true,
                                            perPage: 5,
                                            preloadePages: 1,
                                            autoWidth: true,
                                            height: '200px',
                                            maxHeight: '200',
                                            cover: false,
                                            
                                            cloneStatus: true,
                                            trimSpace: false,
                                            focus: 0,
                                            loop: true,
                                            updateOnMove: 'is-active'
                                        } }
                                        >
 
                                        {channelCategory.channels.map((filteredItem, j) => (
                                        <SplideSlide key={j} className="channelTile">
                                            <Button variant="contained" onClick={() => openChannel(filteredItem.id, j, filteredItem.randomize, filteredItem.channel_image, filteredItem.title, filteredItem.channel_description, filteredItem.base_url, channelCategory.programAPI, filteredItem.channel_type)} style={{padding:'0', position: 'relative'}}>
                                                {filteredItem.channel_image ? (
                                                    <div style={{position: 'relative'}}>
                                                        <span style={{font: theme[0].channel_tile_font, color: theme[0].channel_tile_color, fontSize: theme[0].channel_tile_font_size, textShadow: theme[0].channel_tile_text_shadow, display: `${filteredItem.show_channel_title_on_tile === '1'?'inline-block' : 'none'}`, position: 'absolute', textAlign: 'center', width: '100%', height: '100%', top: theme[0].channel_tile_title_position_top, verticalAlign: 'bottom'}}>{filteredItem.title}</span>

                                                        <img className="tileImg" src={filteredItem.base_url+filteredItem.channel_image} alt={filteredItem.title} style={{border: theme[0].page_image_border, borderRadius: theme[0].page_image_border_radius, boxShadow: theme[0].page_image_shadow, maxHeight: '137px'}} data-splide-lazy="https://shelcaster.tv/images/logo-skeleton.png" />
                                                    </div>
                                                ) : ( 
                                                    <Skeleton style={{color:theme[0].primary_color}} variant="rect" width={236} height={137}>{filteredItem.title}</Skeleton>
                                                )}
                                                {/* {filteredItem.subscription_required === "1" && <div className="channelIcons" style={{position: 'absolute', top: '4px', right: '4px', border: '2px solid #000'}}>
                                                    <div onClick={handleOpenModal}><div className="subscribeButton" style={{display: 'block',verticleAlign: 'middle', backgroundColor: '#000', color: '#fff', padding: '3px 3px 0 3px', textAlign: 'center'}}><FontAwesomeIcon icon={solid('dollar-sign')} /></div></div>
                                                    <Modal
                                                        open={openModal}
                                                        onClose={handleCloseModal}
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <Box sx={style}>
                                                        <Payments state={{id: filteredItem.id, alias: filteredItem.alias, subscription_plan: filteredItem.subscription_plan, price: filteredItem.price, payment_frequency: filteredItem.payment_frequency, title: filteredItem.title}}/>
                                                        </Box>
                                                    </Modal>
                                                </div>} */}
                                                {/* <div className="channelIcons" style={{position: 'absolute', bottom: '2px', right: '2px', display: 'flex', flexDirection:'column', gap: '6px', border: '2px solid #000'}} onClick={(e) => toggleFavorite(e, filteredItem.id, filteredItem.title)}>
                                                    <div className="subscribeButton" style={{}}><FontAwesomeIcon style={{display: 'block',verticleAlign: 'middle', backgroundColor: '#000', color: '#fff', padding: '3px 3px 0 3px', textAlign: 'center'}} icon={regular('heart')} /></div>
                                                </div> */}
                                                <div className="infoBar" style={{position: 'absolute'}}>
                                                    <ChannelModal title={filteredItem.title} description={filteredItem.channel_description} channelId={filteredItem.id}/>
                                                </div>
                                            </Button>
                                            
                                            <Popover
                                                id={openPopoverId}
                                                open={openPopoverId === filteredItem.alias}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                channelId={filteredItem.id}
                                            >
                                                <Typography sx={{ p: 2 }}>{filteredItem.title}</Typography>
                                                <Typography body2="true" component="html" sx={{ p: 2 }} dangerouslySetInnerHTML={{__html: filteredItem.channel_description}}></Typography>
                                            </Popover>
                                        </SplideSlide>
                                        ))}  
                                    </Splide>
                                </div>
                            ))
                        ))}  
                    </Grid>   
                </Grid>           
            </Container>) : (
                <Skeleton style={{color:theme[0].primary_color}} variant="rect" width='100%' height='100vh'>No channels available</Skeleton>
            )}
        </div>
    </>
  )
}
