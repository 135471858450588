import React, { useRef, useState, useEffect } from 'react'
import { Form, Button, Card, Alert, Container } from 'react-bootstrap' 
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Login = () => {
    
    const emailRef = useRef();
    const passwordRef = useRef();

    const { login } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [uid, setUid] = useState('');
    var shelcasterUrl = new URL(localStorage.getItem("shelcasterUrl"));
    const stripe = loadStripe("pk_test_Q0ZvkKS5qrgtX94RPFEJpzlm");

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value)
            
            .then(result => {
                if(result) {
                    const email = emailRef.current.value;
                    localStorage.setItem("customerStripeId", emailRef.current.value);
                    try {
                        if(shelcasterUrl.pathname !== '' || shelcasterUrl.pathname === 'login') {
                            window.location.replace('/');
                        } else {
                            navigate("/")
                        }
                        // fetch(`https://81h2kowb5c.execute-api.us-east-1.amazonaws.com/api/customersByEmail?email=${email}`).then(result=>result.json()) //old endpoint
                        
                        // // fetch(`http://localhost:3000/api/customersByEmail?email=${email}`).then(result=>result.json())
                        // .then(result => {
                        //     const customerStripeId = result.id;
                        //     setCustomer(result.id);
                            
                        // })
                        // .then(()=>{
                        //     if(shelcasterUrl.pathname !== '' || shelcasterUrl.pathname === 'login') {
                        //         window.location.replace('/');
                        //     } else {
                        //         navigate("/")
                        //     }
                        // })
                    } catch (error) {
                    console.error("Error fetching customer ID:", error);
                    }
                    
                }

                // fetch(`http://localhost:3000/api/customersByEmail?email=${email}`)
                // .then(result => result.json())
                // .then(result => {
                //     const customerStripeId = result.id;
                //     const customerPaymentMethod = result.invoice_settings.default_payment_method;
                //     setCustomer(result.id);
                //     localStorage.setItem("customerStripeId", customerStripeId);
                //     // Retrieve the customer from Stripe
                //     return stripe.customers.retrieve(customerStripeId, {
                //         expand: ["invoice_settings.default_payment_method"]
                //     });
                // })
                // .then(customer => {
                //     // Access the default payment method on the customer object
                //     const defaultPaymentMethod = customer.invoice_settings.default_payment_method;
                //     console.log("Default Payment Method: ", defaultPaymentMethod);
                // })
                // .catch(error => {
                //     console.error("Error fetching customer and payment method:", error);
                // });

            })
            .then(() => {
                fetch(`https://shelcaster.tv/user-login?action=post&module=user&resource=login&email=${emailRef.current.value}&password=${passwordRef.current.value}&api_key=LIZWLHAC5EVG13IBGPOZ`)
                localStorage.setItem("userEmail", emailRef.current.value);
            })
            // console.log("uid", useAuth().uid);
            
        } catch {
            setError('Failed to sign in');
        }
        setLoading(false);
    }
    const [customer, setCustomer] = useState(null);

  return (
    <>
        <Container className="align-items-center justify-content-center g-0" style={{ maxWidth: "400px"}}>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Log In</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Button type="submit" className="w-100 mt-3">Log In</Button>
                        {/* <a href="https://sheldonmedia.us.auth0.com/samlp/mJkzhlyWDk0z8wEK31Gt76SzTN13SRIF">Login via SSO</a> */}
                    </Form>
                    < div className="w-100 text-center mt-3">
                        <Link to="/forgot-password">Forgot password?</Link>
                    </div>
                    < div className="w-100 text-center mt-2">
                Need an account? <Link to="/signup">Sign Up</Link>
            </div>
                </Card.Body>
            </Card>
            
        </Container>
    </>
  )
}
export default Login;
