import React from 'react';

function SoundBars() {

    return(
        <div className="soundBarsContainer">
            <div class="soundBars">
                <div class="box box1"></div>
                <div class="box box2"></div>
                <div class="box box3"></div>
                <div class="box box4"></div>
            </div>
        </div>
    )

}

export default SoundBars;