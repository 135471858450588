import { Button } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from '../../App'

export default function SubscribeButton(props) {
    const theme = useContext(ThemeContext)
  return (
    <div>
        <ThemeContext.Provider value={theme}>
            <Button>
                <a href={props.networkPurchaseButton} target="_blank" style={{color: theme[0].title_bar_font_color, fontSize: '.7rem'}}>{props.networkPurchaseLabel}</a>
            </Button>
        </ThemeContext.Provider>
    </div>
  )
}
