import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DashboardMenu from '../dashboard-menu/DashboardMenu'
import { ThemeContext } from '../../App'

const PaymentsDashboard = (props)=> {
  const theme = React.useContext(ThemeContext);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const email = localStorage.getItem('userEmail');
    try {
      
      console.log('user email', email);
      fetch(`http://localhost:4242/api/customersByEmail?email=${email}`).then(result=>result.json())
      .then(result => {
        const customerStripeId = result.id;
        setCustomer(result.id);
        localStorage.setItem("customerStripeId", customerStripeId);
        console.log("result", result);
      })
    } catch (error) {
      console.error("Error fetching customer ID:", error);
    }
  }, [customer]);

  return (
    <div style={{backgroundColor: theme[0].page_background_gradient, color: theme[0].primary_color, padding: '24px'}}>
        <h1>Payments Dashboard</h1>
        <DashboardMenu/>
        <h1>Stripe Customers</h1>
      <ul>
        <li>{customer}</li>
        {/* <li>Test</li> */}
      </ul>
    </div>
  )
}

PaymentsDashboard.propTypes = {}

export default PaymentsDashboard;
