import React, {useState} from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box';

import './payment-dashboard.scss';
import { Link } from 'react-router-dom';

const DashboardMenu = (props) => {
    const preventDefault = (event) => event.preventDefault();
    const [cartItems, setCartItems] = useState([1]);
  return (
    <div className="pageDashboardMenu">
        <Box
            className="menuLinks"
            onClick={preventDefault}
        >
            <Link to="/payment-methods">Payment Methods</Link>
            <Link to="/order-history">Order History</Link>
            {cartItems.length>0 && <Link to="/payments-dashboard/checkout">Checkout</Link>}
            <script async
  src="https://js.stripe.com/v3/buy-button.js">
</script>

<stripe-buy-button
  buy-button-id="buy_btn_1MyN67F30yEnAvWuhYPUoVG3"
  publishable-key="pk_live_nCWnoDAaIAelSNEIluX1dqpI"
>
</stripe-buy-button>
        </Box>
    </div>
  )
}

DashboardMenu.propTypes = {}

export default DashboardMenu;
