import React from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useLocation } from 'react-router-dom';
import { auth } from '../../firebase';
import SetupForm from './SetupForm';
import CheckoutFormV2 from './CheckoutFormV2';
import { Box, Typography } from '@mui/material';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripeSecretKey = process.env.STRIPE_SECRET_TEST_KEY;
// console.log("secret", stripeSecretKey);
const stripePromise = loadStripe("pk_test_Q0ZvkKS5qrgtX94RPFEJpzlm");

const Payments = (props)=> {
  const email = localStorage.getItem("userEmail");
  // const location = props.state; //useLocation();
  const paymentDetails = props.state; //location.state;
  console.log("test", paymentDetails.stripePriceId, paymentDetails.stripeProductId)
  return (
    <>
    <Typography variant="h6" component="h6">
          Pioneer subscription
        </Typography>
      <Box className="modalBodyText" style={{color: 'rgb(1 163 58 / 87%)', padding: '5px'}}>
        
          Help support this effort! With you pre-launch subscription.
      </Box>
      <Elements stripe={stripePromise}>
        {/* <SetupForm /> */}
        <CheckoutFormV2 productDetails={{email: email, title: paymentDetails.title, price: paymentDetails.price, paymentFrequency: paymentDetails.payment_frequency, description: paymentDetails.title, productId: paymentDetails.stripeProductId, priceId: paymentDetails.stripePriceId, debug: paymentDetails.debug}}/>
      </Elements>
    </>
  )
}

export default Payments
