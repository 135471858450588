import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// Replace with your own Stripe publishable key
const stripePromise = loadStripe('pk_test_Q0ZvkKS5qrgtX94RPFEJpzlm');

// Main component to wrap Elements provider
const StripePayment = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm />
    </Elements>
  );
};

// Functional component for the payment form
const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentComplete, setPaymentComplete] = useState(false);

  // Handle submission of the form
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // To integrate server-side, use the secret key to create a PaymentIntent
    // Uncomment the following lines to use your secret key
    const clientSecret = 'pk_test_Q0ZvkKS5qrgtX94RPFEJpzlm';
    const { setError } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: 'John Doe',
        },
      },
    });

    // For demo purposes, create a PaymentMethod without server-side
    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: 'John Doe',
      },
    });

    if (error) {
      setError(error.message);
    } else {
      setPaymentComplete(true);
      console.log('Payment completed:', paymentMethod);
    }
  };

  const handleLinks = (link) => {
    // Implement the logic for handling view payment methods, order history, and checkout here
    console.log(`Navigating to: ${link}`);
  };

  if (paymentComplete) {
    return <h4>Payment completed! Check the console for details.</h4>;
  }

  return (
    <div>
      <h2>Stripe Payment Gateway Integration</h2>
      <div>
        <button onClick={() => handleLinks('paymentMethods')}>View Payment Methods</button>
        <button onClick={() => handleLinks('orderHistory')}>Order History</button>
        <button onClick={() => handleLinks('checkout')}>Checkout</button>
      </div>
      <form onSubmit={handleSubmit}>
        <CardElement />
        {error && <p>{error}</p>}
        <button type="submit" disabled={!stripe}>
          Pay
        </button>
      </form>
    </div>
  );
};

export default StripePayment;