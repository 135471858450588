import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Container } from 'react-bootstrap' 
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

const Signup = (props) => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const usernameRef = useRef();
    const [jbackendUserAPIKey, setJbackendUserAPIKey] = useState(props.jbackendUserAPIKey);
    const [baseUrl, setBaseUrl] = useState(localStorage.getItem('baseUrl') != null ? localStorage.getItem('baseUrl') : 'https://shelcaster.tv/');
    const { signup } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    console.log("jbackend", jbackendUserAPIKey);

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== passwordConfirmRef.current.value) {
            return setError('Passwords do not match');
        }

        try {
            setError('');
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value, firstNameRef.current.value, lastNameRef.current.value, usernameRef.current.value);
            navigate("/")
            await fetch(`${baseUrl}user-register?action=post&module=user&resource=register&email=${emailRef.current.value}&username=${usernameRef.current.value}&firstname=${firstNameRef.current.value}&lastname=${lastNameRef.current.value}&password=${passwordRef.current.value}&api_key=${jbackendUserAPIKey}`);
            // http://nodedockereb-env.eba-saqthsbz.us-east-1.elasticbeanstalk.com
            await fetch('http://nodedockereb-env.eba-saqthsbz.us-east-1.elasticbeanstalk.com', {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  email: emailRef.current.value,
                  name: firstNameRef.current.value + " " + lastNameRef.current.value,

                }),
              }).then(r => r.json());
        } catch {
            setError('Failed to create account');
        }

        setLoading(false);
    }
  return (
    <>
        <Container className="align-items-center justify-content-center g-0" style={{ maxWidth: "400px"}}>
            <Card>
                <Card.Body>
                    <h2 className='text-center mb-4'>Sign Up</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" ref={emailRef} required />
                        </Form.Group>
                        <Form.Group id="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" ref={usernameRef} required />
                        </Form.Group>
                        <Form.Group id="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" ref={passwordRef} required />
                        </Form.Group>
                        <Form.Group id="password-confirm">
                            <Form.Label>Password Confirmation</Form.Label>
                            <Form.Control type="password" ref={passwordConfirmRef} required />
                        </Form.Group>
                        <Form.Group id="firstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" ref={firstNameRef} required />
                        </Form.Group>
                        <Form.Group id="lastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" ref={lastNameRef} required />
                        </Form.Group>
                        <Button type="submit" className="w-100 mt-3">Sign Up</Button>
                    </Form>
                    < div className="w-100 text-center mt-2">
                        Already have an account? <Link to="/login">Log In</Link>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    </>
  )
}
export default Signup;
