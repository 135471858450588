import React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Bookmark from 'react-bookmark';
import { Link } from 'react-router-dom';

function ChannelDetailsModalContent(props) {
    const channelID = props.channelId;
      

    return (
        <React.Fragment>
            <div className="modalDetailsBtn" onClick={props.handleOpen}>View Details  <InfoIcon /></div>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{backgroundColor: 'rgba(0,0,0,.6)', border: '2px solid #fff'}}
            >
                <Box sx={props.style} style={{backgroundColor: 'rgba(0,0,0,.6)', border: '1px solid #fff'}}>
                    <Typography style={{background: 'none', color: '#fff'}} id="modal-modal-title" variant="h6" component="h2">
                        {props.title}
                    </Typography>
                    <Typography style={{background: 'none', color: '#fff'}} body2="true" component="body" dangerouslySetInnerHTML={{__html: props.description}}/>
                </Box>
            </Modal>
        </React.Fragment>
    )
}
export default ChannelDetailsModalContent