import React from 'react';
import StripePayment from '../../components/stripe/StripePayment';

export default {
  title: 'Payments/Payment',
  component: StripePayment,
  // More on argTypes: https://storybook.js.org/docs/react/api/argtypes
  parameters: {
    // More on Story layout: https://storybook.js.org/docs/react/configure/story-layout
    layout: 'fullscreen',
  },
};

const Template = (args) => <StripePayment {...args} />;

export const CheckOut = Template.bind({});
CheckOut.args = {
  id: '123',
  price: 3700,
}