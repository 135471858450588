import React, { useEffect, useState } from 'react'
import { Button, Card, Alert, Container } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import { auth, db } from '../../firebase';
import useUserData from '../../hooks/usersHook';

export default function Profile() {
  const [error, setError] = useState("");
  const {currentUser, logout} = useAuth();
  const [userDetails, setUserDetails] = useUserData(currentUser.uid, db);

  return (
    <>
      <Container className="align-items-center justify-content-center g-0">
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Profile</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <strong>Name: {currentUser.displayName}</strong><br/>
            <strong>Email: </strong>{currentUser.email}
            {userDetails && userDetails.map((userItem, i) => (
              <div key={Math.random()}>
                {userItem.data.company && <span className="label">Company: </span>}{userItem.data.company && userItem.data.company}<br/>
                {userItem.data.phone && <span className="label">Phone: </span>}{userItem.data.phone && userItem.data.phone}
              </div>
            ))}
            <Link to="/update-profile" className="btn btn-primary w-100">Update Profile</Link>
          </Card.Body>
        </Card>
      </Container>
    </>
  )
}