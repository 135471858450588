import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';

function Mp3DownloadLink({ href, fileName }) {
  const handleClick = (e) => {
    e.preventDefault();
    fetch(href)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <a href={href} onClick={handleClick}>
      <DownloadIcon />
    </a>
  );
}

export default Mp3DownloadLink;