import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TemporaryDrawer from '../web-components/Drawer';
import Grid from '@mui/material/Grid';
import { SitePropertiesContext, ThemeContext } from '../App';
import './appbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { auth } from '../../firebase';
import { Form} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Payments from '../stripe/Payments';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useContext, useState, useEffect } from 'react';
import SubscribeButton from '../stripe/buttons/SubscribeButton';


export default function DenseAppBar(props) {
  const theme = useContext(ThemeContext);
  const [priceId, setPriceId] = useState(null);
  const [productId, setProductId] = useState(null);
  const siteProperties = useContext(SitePropertiesContext);
  useEffect(()=>{
    if(siteProperties[0].debug === '1') {
      setPriceId(siteProperties[0].stripe_price_id_test);
      setProductId(siteProperties[0].stripe_product_id_test);
    } else {
      setPriceId(siteProperties[0].stripe_price_id);
      setProductId(siteProperties[0].stripe_product_id);
    }
  },[priceId, productId])
  console.log("price and product", priceId, productId);
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  console.log("current user", currentUser);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
  };
  const [baseUrl, setBaseUrl] = useState(props.baseUrl);
  const networkName = props.networkName ? props.networkName : localStorage.getItem("networkName");
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopoverId, setOpenPopoverId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleLogout = () => {
    auth.signOut()
    .then(()=>{
      fetch('https://shelcaster.tv/user-logout?action=post&module=user&resource=logout')
    })
    .then(() => {
      setCurrentUser(auth.currentUser);
    })
      
  }

  //Stripe Code
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (e) => {
      e.stopPropagation();
      setOpenModal(true);
  }
  const handleCloseModal = (e) => {
      e.stopPropagation();
      setOpenModal(false);
  }
  
  return (
    <SitePropertiesContext.Provider value={siteProperties}>
      <Box sx={{ flexGrow: 1 }} className="appbarHeader">
        <AppBar position="static">
          <Toolbar variant="dense" style={{backgroundColor: theme[0].title_bar_background_color, color: theme[0].title_bar_font_color}}>
            <Grid container>
              <Grid item xs={1} md={2}>
                <div edge="start" aria-label="menu">
                  {theme && <TemporaryDrawer sideMenu={props.sideMenu} />}
                </div>
              </Grid>
              <Grid item xs={10} md={8} style={{textAlign: 'center'}}>
                {theme[0].side_menu_logo ? ( <img className="logoProperties" src={baseUrl + theme[0].side_menu_logo} style={{maxWidth: '100%', padding: '6px 0 6px 0', margin: '4px'}} sx={4}/>) : (
                  <span className="mainLogo">{networkName}</span>
                )}
              </Grid>
              {currentUser && siteProperties[0].subscription_required === '1' && <Grid item xs={4} md={2}>
                <SubscribeButton appTitle={siteProperties[0].app_title} networkPurchaseButton={siteProperties[0].network_purchase_button} networkPurchaseLabel={siteProperties[0].network_purchase_label} />
                {/* <div onClick={handleOpenModal}>
                  <Button className="subscribeButton" style={{display: 'block', verticleAlign: 'middle', backgroundColor:'#000', color: '#fff', marginTop: '24px', padding: '3px 3px 0 3px', textAlign: 'center'}}>Subscribe <FontAwesomeIcon icon={solid('dollar-sign')} /></Button>
                </div>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                    <Payments state={{id: 'network-subscription1', subscription_plan: 'monthly-commercial', price: siteProperties[0].subscription_network_price, payment_frequency: siteProperties[0].subscription_network_frequency, description: 'Monthly Subscription', title:siteProperties[0].app_title, stripeProductId: productId, stripePriceId: priceId, debug: siteProperties[0].debug}}/>
                    </Box>
                </Modal> */}
              </Grid>}
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </SitePropertiesContext.Provider>
  );
}
