import React, { useEffect, createContext } from "react";
import { Container } from "react-bootstrap";
import { AuthProvider, useAuth} from "../contexts/AuthContext";
import Signup from "./authentication/Signup";
import {BrowserRouter as Router, Routes, Route, useParams} from "react-router-dom";
import Profile from "./Profile/Profile";
import Login from "./authentication/Login";
import PrivateRoute from './authentication/PrivateRoute';
import ForgotPassword from "./authentication/ForgotPassword";
import UpdateProfile from "./Profile/UpdateProfile";
import Dashboard from "./Dashboard/Dashboard";
import Broadcast from './broadcast/Broadcast';
import '../styles/global.scss';
import Banners from "./banners/Banners";
import DenseAppBar from "./topHeaderBar/Appbar";
import { ContentCutOutlined } from "@mui/icons-material";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas, faFontAwesome } from '@fortawesome/free-solid-svg-icons'
import UploadForm from "./admin/storage/UploadForm";
import MediaStore from "./media_store/MediaStore";
import Payments from "./stripe/Payments";
import Prices from "./stripe/subscription-flow/Prices";
import Subscribe from "./stripe/subscription-flow/Subscribe";
import Account from "./stripe/subscription-flow/Account";
import Cancel from "./stripe/subscription-flow/Cancel";
import Register from "./stripe/subscription-flow/Register";
import PaymentsDashboard from "./stripe/dashboard/PaymentsDashboard";
import { CheckOut } from "../stories/payments/PaymentPortal.stories";


library.add(fas, faFontAwesome)

export const ThemeContext = createContext(null);
export const SitePropertiesContext = createContext(null);

function App() {
  
  const [sideMenuAPI, setSideMenuAPI] = React.useState(null);
  const [dashboardMenuAPI, setDashboardMenuAPI] = React.useState(null);
  const [themesAPI, setThemesAPI] = React.useState(null);
  const [bannersAPI, setBannersAPI] = React.useState(null);
  const [articlesAPI, setArticlesAPI] = React.useState(null);
  const [externalNetworksAPI, setExternalNetworksAPI] = React.useState(null);
  const [channelsAPI, setChannelsAPI] = React.useState(null);
  const [singleChannelAPI, setSingleChannelAPI] = React.useState(null);
  const [programsAPI, setProgramsAPI] = React.useState(null);
  const [mediaStoreAPI, setMediaStoreAPI] = React.useState(null);
  const [jbackendUserAPIKey, setJbackendUserAPIKey] = React.useState(null);
  const [sitePropertiesAPI, setSitePropertiesAPI] = React.useState(null);

  const [articles, setArticles] = React.useState(null);
  const [channelCategories, setChannelCategories] = React.useState(null);
  const [sideMenu, setSideMenu] = React.useState(null);
  const [dashboardMenu, setDashboardMenu] = React.useState(null);
  const [theme, setTheme] = React.useState(null);
  const [siteProperties, setSiteProperties] = React.useState(null);
  const [pageBackground, setPageBackground] = React.useState(null);
  const [banners, setBanners] = React.useState(null);
  const [baseUrl, setBaseUrl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showBanner, setShowBanner] = React.useState(true);
  const [networkName, setNetworkName] = React.useState('');
  const [broadcastAPI, setBroadcastAPI] = React.useState(null);

  var hostname = window.location.hostname;
  hostname = hostname.split(".");
  hostname = hostname[0];
  // if(hostname.length > 2) {
  //   hostname = hostname[0];
  // } else {
  //   hostname = "shelcaster-tv";
  // }
 
  localStorage.setItem("clientId", hostname);
  localStorage.setItem("shelcasterUrl", window.location.href);
  const [clientID, setClientID] = React.useState(hostname);

  function removeBanner() {
      setShowBanner(false)
  }

  // const handleSearch = (e) => {
  //   if(e.target.value.length > 2) {
  //     console.log("search", e.target.value);
  //   }
    
  // }

  //API to call host (primary) network

  React.useEffect(() => {
    async function getNetworks() {
      console.log("enc", process.env.SHELCASTER_API_ENDPOINT);
        const response = await fetch(`https://dmb6bpx75m.execute-api.us-east-1.amazonaws.com/networks?clientID=${clientID}`);
        const networks = await response.json();
        localStorage.setItem("clientID", clientID);
        
        // if(!clientID){
        //     window.location.hostname = "https://shelcaster-tv.shelcaster.com";
        // }
        return networks;
    }
    getNetworks().then(networks => {
        setSideMenuAPI(networks[0].sidemenu_api);
        setDashboardMenuAPI(networks[0].dashboard_menu_api);
        setThemesAPI(networks[0].themes_api);
        setBannersAPI(networks[0].banners_api);
        setArticlesAPI(networks[0].articles_api);
        setExternalNetworksAPI(networks[0].external_networks_api);
        setBaseUrl(networks[0].base_url);
        setBroadcastAPI(networks[0].broadcast_api);
        setChannelsAPI(networks[0].channels_api);
        setSingleChannelAPI(networks[0].channel_single_api);
        setProgramsAPI(networks[0].programs_api);
        setMediaStoreAPI(networks[0].media_store_api);
        setJbackendUserAPIKey(networks[0].jbackend_api_key);
        setSitePropertiesAPI(networks[0].site_properties_api);
        console.log("Site Properties in setAPI", sitePropertiesAPI);

        localStorage.setItem("baseUrl", networks[0].base_url);
    },
    (error) => {
        console.log("get networks", error.message);
    });

  },[clientID, bannersAPI])

  // API to call list of external (shared) networks
  const categoryArray = [];
  const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
  };

  React.useEffect(() => {
    async function getExternalNetworks() {
        const response = await fetch(externalNetworksAPI);
        const extNetworks = await response.json();
        return extNetworks;
    }

    getExternalNetworks().then(extNetworks => {
        extNetworks.map((network, i) => {
            async function getCategories() {
                const res = await fetch(network.channel_categories_api);
                const data = await res.json();
                return data;
            }

            getCategories().then(data => {
                // console.log("categories data");
                console.log("data", data);
                data.map((channelSet, j) => {
                    fetch(`${network.channels_api}?categoryID=${channelSet.id}`)
                    .then(resp => resp.json())
                    .then(resp => {
                        channelSet['channels'] = resp;
                        channelSet['programAPI'] = network.programs_api;
                        channelSet['networkID'] = network.id;
                        channelSet['ordering'] = network.ordering;
                        categoryArray.push(channelSet);
                        console.log("channel set resp", resp);
                    },
                    (error) => {
                        console.log("channel set", error.message);
                    })
                },
                (error) => {
                    console.log("map categories", error.message);
                })
            },
            (error) => {
                console.log("get categories", error.message);
            })
        })
    })
    .then(() => {
        setTimeout(() => {

            categoryArray.sort(function(a, b){
                let x = a.title.toLowerCase();
                let y = b.title.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
            });
              
            let categoryGrouped = groupBy(categoryArray, 'ordering');
            console.log("categoryGrouped", categoryGrouped);
            var categoryArrayGrouped = Object.keys(categoryGrouped).map(function(key) {
                return [Number(key), categoryGrouped[key]];
            });
            setChannelCategories(categoryArrayGrouped);
            setIsLoading(false);
        },6000);
        
    },(error) => {
        console.log("external networks", error.message);
    });
  },[externalNetworksAPI]);

  React.useEffect(() => {
    fetch(`${articlesAPI}?articleCategory=dashboard`)
    .then(result => result.json())
    .then(result => {
        if(result) {
          setArticles(result);
        }
    },
    (error) => {
        console.log(error.message);
    })
  },[articlesAPI])

  React.useEffect(() => {
    fetch(sideMenuAPI)
    .then(result => result.json())
    .then(result => {
        setSideMenu(result);
        // console.log("side menu in app", result)
    })
  },[sideMenuAPI])

  React.useEffect(() => {
    fetch(dashboardMenuAPI)
    .then(result => result.json())
    .then(result => {
        setDashboardMenu(result);
    })
  },[dashboardMenuAPI])

  React.useEffect(() => {
    fetch(bannersAPI)
    .then(result => result.json())
    .then(result => {
        setBanners(result);
    },
    (error) => {
        console.log(error.message);
    })
  }, [bannersAPI])

  React.useEffect(() => {
    fetch(themesAPI)
    .then(result => result.json())
    .then(result => {
        setTheme(result);
        localStorage.setItem("theme", result);
    },
    (error) => {
        console.log(error.message);
    })
    .then(() =>{
      setPageBackground(theme[0].page_background_image);
    })
  },[themesAPI, pageBackground])

  React.useEffect(() => {
    fetch(sitePropertiesAPI)
    .then(result => result.json())
    .then(result => {
      console.log("Site properties in useEffect", sitePropertiesAPI);
      setSiteProperties(result);
      console.log("Site properties in useEffect", result);
    }, 
    (error) => {
      console.log("Site properties Error", error.message);
    })
  }, [sitePropertiesAPI])

  return (
    <ThemeContext.Provider value={theme}>
      <SitePropertiesContext.Provider value={siteProperties}>
        <div style={{backgroundImage: `url(https://shelcaster.tv/images/background1.jpg)`, backgroundSize: 'cover'}}>
          {isLoading ? (
            <div className="pulse"><img style={{maxWidth: '80%', margin: '100px auto'}} src="https://shelcaster.tv/images/sc-logo-clear.png" alt="Shelcaster"/></div>
          ) : (
            <Router>
          
            {<DenseAppBar sideMenu={sideMenu} baseUrl={baseUrl} networkName={networkName} channelCategories={channelCategories}/>}
            <Container fluid className="d-flex align-items-center justify-content-center g-0 backgroundColorDiv pageWrapper" style={{ minHeight: "100vh", height: '100%', background: `${theme[0].page_background_color} url(${baseUrl + theme[0].page_background_image})`}}>
            
              <div className="w-100" style={{alignSelf: 'start'}}>
                
                  <AuthProvider>
                    <Routes>
                      <Route exact path="/" element={<PrivateRoute/>}>
                        <Route exact path="/" element={<Dashboard baseUrl={baseUrl} bannersAPI={bannersAPI} dashboardMenu={dashboardMenu} sideMenu={sideMenu}/>}/>
                      </Route>
                      <Route path="/dashboard" element={<PrivateRoute/>}>
                        <Route exact path="/dashboard" element={<Dashboard baseUrl={baseUrl} bannersAPI={bannersAPI} sideMenu={sideMenu} dashboardMenu={dashboardMenu}/>}/>
                      </Route>
                      {/* <Route exact path="/" element={<PrivateRoute/>}>
                        <Route exact path="/" element={<Broadcast channelCategories={channelCategories} baseUrl={baseUrl} onChannelClick={removeBanner} theme={theme}/>}/>
                      </Route> */}
                      <Route exact path="/profile" element={<PrivateRoute/>}>
                        <Route exact path="/profile" element={<Profile/>}/>
                      </Route>
                      {/* <Route exact path="/directory" element={<PrivateRoute/>}>
                        <Route exact path="/directory" element={<UserList/>}/>
                      </Route> */}
                      <Route exact path="/update-profile" element={<PrivateRoute/>}>
                        <Route exact path="/update-profile" element={<UpdateProfile/>}/>
                      </Route>
                      <Route exact path="/file-upload" element={<PrivateRoute/>}>
                        <Route exact path="/file-upload" element={<UploadForm/>}/>
                      </Route>
                      <Route path="/broadcast" element={<PrivateRoute/>}>
                        <Route path="/broadcast/:channelId/:programId" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} />}/>
                        <Route path="/broadcast/:channelId" element={<Broadcast programsAPI={programsAPI} channelCategories={channelCategories} singleChannelAPI={singleChannelAPI} baseUrl={baseUrl} onChannelClick={removeBanner} />}/>
                        <Route exact path="/broadcast" element={<Broadcast channelCategories={channelCategories} baseUrl={baseUrl} onChannelClick={removeBanner} />}/>
                      </Route>
                      <Route path="/media-store" element={<PrivateRoute />}>
                        <Route path="/media-store" element={<MediaStore mediaStoreAPI={mediaStoreAPI} />}/>
                      </Route>
                      <Route path="/signup" element={<Signup jbackendUserAPIKey={jbackendUserAPIKey} />}/>
                      <Route path="/login" element={<Login />}/>
                      <Route path="/forgot-password" element={<ForgotPassword />}/>
                      <Route exact path="/register" element={<Register />}/>

                      <Route path="/prices" element={<Prices />}/>
                      <Route path="/subscribe" element={<Subscribe />}/>
                      <Route path="/account" element={<Account />}/>
                      <Route path="/cancel" element={<Cancel />}/>

                      <Route path="/payments-dashboard" element={<PrivateRoute />}>
                        <Route path="/payments-dashboard" element={<PaymentsDashboard/>}/>
                        <Route path="checkout" element={<CheckOut/>}/>
                      </Route>
                    </Routes>
                  </AuthProvider>

              </div>
            </Container>
          
          </Router>
          )}
        </div>
      </SitePropertiesContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
