import { RestoreOutlined } from '@mui/icons-material';
import React from 'react';
import { useEffect } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';

const MediaStore = (props) => {
    const mediaStoreAPI = props.mediaStoreAPI;
    useEffect(() => {
        fetch(mediaStoreAPI)
        .then(result => result.json())
        .then(result => {
            console.log("beats", result);
        })
    }, [mediaStoreAPI])
    
    return (
        <Container>
            <h2>Beats</h2>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Row>
                        <Col>Image</Col>
                        <Col>Track </Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>Dapibus ac facilisis in</ListGroup.Item>
                <ListGroup.Item>Morbi leo risus</ListGroup.Item>
                <ListGroup.Item>Porta ac consectetur ac</ListGroup.Item>
            </ListGroup>
        </Container>

    );
}
export default MediaStore;